import { Box, styled } from "@mui/material"
import Typography from "@mui/material/Typography"
import { createPath, ROUTE } from "app-constants/routing"
import { ContentContainer } from "components/content-container"
import MainLayout from "components/main-layout"
import AppLink from "components/navigation/AppLink"
import PageTitle from "components/page-title"
import buildStaticPage from "lib/build-static-page"
import React from "react"
import { pxToRem } from "utils/helpers"

// NOTE: This MUST be a static page, nextjs doesn't allow it to be SSR
export default buildStaticPage(function NotFoundPage() {
  return (
    <>
      <PageTitle>Page Not Found</PageTitle>
      <MainLayout layout="macro">
        <ContentContainer>
          <_Container>
            <_Container>
              <_EmojiContainer aria-hidden={true}>
                {/*Emoji: Woman Shrugging 🤷‍♀️*/}
                &#129335;&#8205;&#9792;&#65039;
              </_EmojiContainer>
              <Typography variant="paragraphRegular" color="gray.darker">
                {`Oops! It looks like this page doesn't exist or the URL may have changed.`}
              </Typography>
              <AppLink href={createPath({ path: ROUTE.CONVENTIONS })}>go home</AppLink>
            </_Container>
          </_Container>
        </ContentContainer>
      </MainLayout>
    </>
  )
})

const _Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "nowrap column",
  alignItems: "center",
  gap: pxToRem(50),
  padding: `${pxToRem(25)} 0`,
  textAlign: "center",

  [theme.breakpoints.up("sm")]: {
    padding: `${pxToRem(50)} 0`,
  },
  [theme.breakpoints.up("md")]: {
    padding: `${pxToRem(75)} 0`,
  },
})) as typeof Box

const _EmojiContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: pxToRem(46),
})) as typeof Box
