import { Props } from "lib/page-types"
import useCurrent from "lib/use-current"
import React from "react"

export default function buildStaticPage(
  Page: (props: Props) => JSX.Element,
): (props: Props) => JSX.Element {
  // @ts-ignore
  return function StaticPageWrapperComponent(props) {
    // Every page needs current context so make it standard how it loads
    // once then everything after here that also calls it will already have it
    // available
    const current = useCurrent()
    if (!current) {
      return null // could be a loading indicator, or just blank for now
    }
    return (
      <>
        <Page {...props} />
      </>
    )
  }
}
